import {getUserAPI} from "../api/users";
import {getCustomerAPI} from "../api/customers";

const fetchInviteData = async (location, setInvitation, setLoadingUserInviteData) => {
  const pathname = location.pathname

  if (pathname.includes('companyInvite')) {
    const match = pathname.toLowerCase().match(/companyinvite\/([^/]+)/)
    const inviteIdFromUrl = match ? pathname.split('/').pop() : null
    const user = await getUserAPI(inviteIdFromUrl).then(response => response.json())
    if (user?.invite_accepted === false) {
      setInvitation({
        id: inviteIdFromUrl,
        type: 'companyInvite',
        userEmail: user.email,
        companyId: user.customer_id,
        pathname: location.pathname,
      })
    } else {
      console.log('No user invited found!')
    }
  } else if (pathname.includes('companyOnboarding')){
    console.log('match companyOnboarding')
    const match = pathname.toLowerCase().match(/companyonboarding\/([^/]+)/)
    const inviteIdFromUrl = match ? pathname.split('/').pop() : null
    const customer = await getCustomerAPI(inviteIdFromUrl).then(response => response.json())
    if (customer?.invite_accepted === false) {
      setInvitation({
        id: inviteIdFromUrl,
        type: 'companyOnboarding',
        userEmail: customer.email,
        companyId: customer.id,
        companyName: customer.name,
        pathname: location.pathname,
      })
    } else {
      console.log('No customer invited found!')
    }
  }
  setLoadingUserInviteData(false)
}

export {
  fetchInviteData,
}
