import React from 'react';
import {
    Modal, Backdrop,
    CircularProgress,
    Box,
    Typography,
    IconButton,
    Table,
    TableBody,
    TableRow,
    TableCell,
    List,
    ListItem,
    Tooltip,
    TextField,
    Button,
    FormControl,
    FormLabel,
    Select,
    MenuItem
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useUser } from '../contexts/UserContext';
import { submitFeedback } from '../api/fetchFairway';
import { updateFeedback } from '../database/services/feedback';
import { feedbackOptions } from '../scenes/Tools/constants/avaAuditorConstants';
import FairwayChip from './FairwayChip';
import EditIcon from '@mui/icons-material/Edit';
import FairwayDocumentDisplay from './FairwayDocumentDisplay';
import FairwaySnackbar from './FairwaySnackbar';

const AvaRationaleModal = ({ open, onClose, selectedRun }) => {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const initialFeedback = new Map(Object.entries(selectedRun?.feedback?.additionalFeedback || {}));
    const [additionalFeedback, setAdditionalFeedback] = React.useState(initialFeedback || new Map());
    const [feedbackText, setFeedbackText] = React.useState(selectedRun?.feedback?.feedbackText || '');
    const { company } = useUser();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarType, setSnackbarType] = React.useState('success');
    const sortedRequirements = Object.keys(selectedRun?.rationale?.requirements || {}).sort((a, b) => {
        const metA = selectedRun?.rationale.requirements[a].met;
        const metB = selectedRun?.rationale.requirements[b].met;
        return metA === metB ? 0 : metA ? 1 : -1; 
    });

    if (selectedRun && selectedRun.feedback === undefined) {
        selectedRun.feedback = {
            isResultCorrect: null,
            additionalFeedback: {},
            feedbackText: '',
            isFinal: false
        }
    }


    const handleFeedbackSubmit = async (isResultCorrect = false, isFinal = 'false') => {
        setIsSubmitting(true);
        await sendFeedback(isResultCorrect, isFinal);
        setIsSubmitting(false);
    }

    const sendFeedback = async (isResultCorrect, isFinal) => {
        try {
            const tool = 'AvaAuditor';
            const feedbackData = {
                customer: company.id,
                tool,
                tool_run: selectedRun?.id,
                trace_run_id: selectedRun?.trace_run_id,
                key: 'user_feedback',
                score: isResultCorrect ? 1.0 : 0.0,
                correction: isResultCorrect ? null : Object.fromEntries(additionalFeedback),
                comment: feedbackText || null,
            }

            const response = await submitFeedback(feedbackData);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const update = {
                isResultCorrect,
                additionalFeedback: Object.fromEntries(additionalFeedback),
                feedbackText,
                isFinal
            }

            selectedRun.feedback = update;

            await updateFeedback(company.id, tool, selectedRun?.id, update);

            setSnackbarMessage('Feedback submitted successfully!');
            setSnackbarType('success');
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage('An error occurred while submitting feedback');
            setSnackbarType('error');
            setSnackbarOpen(true);
        }
    }


    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
        >
            <>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    open={isSubmitting}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <FairwaySnackbar
                    open={snackbarOpen}
                    onClose={
                        () => {
                            setSnackbarOpen(false);
                        }
                    }
                    message={snackbarMessage}
                    type={snackbarType}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxHeight: '90%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 2,
                        overflowY: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <Typography variant="h6" id="modal-modal-title">
                            Rationale - {selectedRun?.rationale
                                ? `${selectedRun?.rationale?.location?.state}/${selectedRun?.rationale?.location?.county}`
                                : undefined}
                        </Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {selectedRun && (
                        <Box>
                            <Table>
                                <TableBody>
                                    {/* Approved Row */}
                                    <TableRow
                                        key="approved"
                                        sx={{
                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        }}
                                    >
                                        <TableCell
                                            colSpan={2} // Spans the first two columns
                                            style={{
                                                textAlign: 'left',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            <Tooltip
                                                title={selectedRun.approved === 'Approved' ? 'This document will likely be approved, please review the details below' : 'This document will likely be rejected, please review the details below'}
                                            >
                                                <span>
                                                    <FairwayChip
                                                        displayText={selectedRun.approved === 'Approved' ? 'Approved' : 'Rejected'}
                                                        color={selectedRun.approved === 'Approved' ? 'success' : 'error'}
                                                    />
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Tooltip key={`${feedbackOptions.thumbsup.tooltip}-thumbsUp`} title={feedbackOptions.thumbsup.tooltip}>
                                                <span>
                                                    <IconButton
                                                        onClick={() => {
                                                            if (selectedRun.feedback && selectedRun.feedback.isResultCorrect === true) {
                                                                selectedRun.feedback.isResultCorrect = null;
                                                                return;
                                                            }
                                                            if (selectedRun.feedback) {
                                                                selectedRun.feedback.isResultCorrect = true;
                                                            }
                                                            setAdditionalFeedback(new Map());
                                                            handleFeedbackSubmit(true, true);
                                                        }}
                                                        sx={{ color: selectedRun.feedback?.isResultCorrect ? 'primary.main' : 'grey.500' }}
                                                    >
                                                        {feedbackOptions.thumbsup.icon}
                                                    </IconButton>
                                                </span>
                                            </Tooltip>

                                            <Tooltip key={`${feedbackOptions.thumbsdown.tooltip}-viewDocument`} title={feedbackOptions.thumbsdown.tooltip}>
                                                <span>
                                                    <IconButton
                                                        onClick={() => {
                                                            if (selectedRun.feedback && selectedRun.feedback.isResultCorrect === false) {
                                                                selectedRun.feedback.isResultCorrect = null;
                                                                setAdditionalFeedback(new Map());
                                                                return;
                                                            }
                                                            if (selectedRun.feedback) {
                                                                selectedRun.feedback.isResultCorrect = false;
                                                            }
                                                            setAdditionalFeedback(new Map());
                                                        }}
                                                        sx={{ color: selectedRun.feedback?.isResultCorrect === false ? 'primary.main' : 'grey.500' }}
                                                    >
                                                        {feedbackOptions.thumbsdown.icon}
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            {selectedRun.feedback?.isResultCorrect && (
                                                <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
                                                    Thank you for your feedback!
                                                </Box>
                                            )}
                                            {selectedRun.feedback?.isResultCorrect === false && (
                                                <Box sx={{ color: 'error.main', fontWeight: 'bold' }}>
                                                    Please check the sections that are incorrect
                                                </Box>
                                            )}
                                        </TableCell>
                                    </TableRow>

                                    {/* Items for Review */}
                                    <TableRow key="next_steps">
                                        <TableCell>
                                            <b>ITEMS FOR REVIEW</b>
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <List sx={{ listStyleType: 'disc' }}>
                                                {Object.keys(selectedRun.next_steps || {}).map((step) => (
                                                    <ListItem key={step} sx={{ display: 'list-item' }}>
                                                        {selectedRun.next_steps[step]}
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </TableCell>
                                    </TableRow>

                                    {/* Detailed Analysis Header */}
                                    <TableRow key="requirements">
                                        <TableCell style={{ borderBottom: 'none' }}>
                                            <b>DETAILED ANALYSIS</b>
                                        </TableCell>
                                        <TableCell colSpan={2} style={{ borderBottom: 'none' }}></TableCell>
                                    </TableRow>

                                    {/* Dynamic Rows for Detailed Analysis */}
                                    {sortedRequirements.map((key) => (
                                        <React.Fragment key={key}>
                                            <TableRow key={`${key}-name`}>
                                                <TableCell style={{ borderBottom: 'none' }}>
                                                    <b>{selectedRun.rationale.requirements[key].name}</b>
                                                </TableCell>
                                                <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
                                                    <Tooltip
                                                        title={selectedRun.rationale.requirements[key]['met'] ? 'This document is completed satisfactory for this particular location and transaction type' : 'This document is not completed satisfactory for this particular location and transaction type'}
                                                    >
                                                        <span>
                                                            <FairwayChip
                                                                displayText={selectedRun.rationale.requirements[key]['met'] ? 'Correct' : 'Incorrect'}
                                                                color={selectedRun.rationale.requirements[key]['met'] ? 'success' : 'error'}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip
                                                        title={selectedRun.rationale.requirements[key].details['required'] ? 'This document is mandatory for this particular location and transaction type' : 'This document is not mandatory for this particular location and transaction type'}
                                                    >
                                                        <span>
                                                            <FairwayChip
                                                                displayText={selectedRun.rationale.requirements[key].details['required'] ? 'Required' : 'Not required'}
                                                                color={selectedRun.rationale.requirements[key].details['required'] ? 'information' : 'warning'}
                                                            />
                                                        </span>
                                                    </Tooltip>

                                                </TableCell>
                                            </TableRow>
                                            {selectedRun.feedback?.isResultCorrect === false && (
                                                <TableRow key={`${key}-feedback`}
                                                    sx={
                                                        {
                                                            borderBottom: '1px solid rgba(81, 81, 81, 1)',
                                                        }
                                                    }
                                                >
                                                    <TableCell
                                                        // keep it fixed height even when the cell bellow is rendered
                                                        sx={
                                                            {
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                border: 'none',
                                                            }
                                                        }
                                                    >
                                                        <Tooltip key={`${feedbackOptions.thumbsdown.tooltip}-viewDocument`} title={feedbackOptions.thumbsdown.tooltip}>
                                                            <span>
                                                                <IconButton
                                                                    onClick={() => setAdditionalFeedback((prev) => {
                                                                        const newFeedback = new Map(prev);
                                                                        if (newFeedback.get(selectedRun.rationale.requirements[key].name)?.isCorrect === false) {
                                                                            newFeedback.delete(selectedRun.rationale.requirements[key].name);
                                                                        } else {
                                                                            newFeedback.set(selectedRun.rationale.requirements[key].name, {
                                                                                ...newFeedback.get(selectedRun.rationale.requirements[key].name),
                                                                                isCorrect: false,
                                                                            });
                                                                        }
                                                                        return newFeedback;
                                                                    })}
                                                                    sx={{
                                                                        color: additionalFeedback.get(selectedRun.rationale.requirements[key].name)?.isCorrect === false ? 'primary.main' : 'grey.500'
                                                                    }}
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell colSpan={2}
                                                        sx={
                                                            {
                                                                border: 'none',
                                                            }
                                                        }
                                                    >
                                                        {additionalFeedback.get(selectedRun.rationale.requirements[key].name)?.isCorrect === false &&
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'flex-start',
                                                                    alignItems: 'flex-start',
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={
                                                                        {
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            justifyContent: 'space-between',
                                                                            alignItems: 'center',
                                                                            width: '100%',
                                                                        }
                                                                    }
                                                                >
                                                                    <FormControl sx={{
                                                                        mb: 2,
                                                                        minWidth: "40%",
                                                                        marginRight: 20
                                                                    }}>
                                                                        <FormLabel>Was this requirement marked correctly?</FormLabel>
                                                                        <Select
                                                                            value={additionalFeedback.get(selectedRun.rationale.requirements[key].name)?.wasMet || []}
                                                                            onChange={(e) => setAdditionalFeedback((prev) => new Map(prev.set(selectedRun.rationale.requirements[key].name, {
                                                                                ...prev.get(selectedRun.rationale.requirements[key].name),
                                                                                wasMet: e.target.value,
                                                                            })))}
                                                                            renderValue={(selected) => selected}
                                                                        >
                                                                            <MenuItem value="">None</MenuItem>
                                                                            <MenuItem value="Correct">Correct</MenuItem>
                                                                            <MenuItem value="Incorrect">Incorrect</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                    <FormControl sx={{
                                                                        mb: 2,
                                                                        minWidth: "40%"
                                                                    }}>
                                                                        <FormLabel>Was this required?</FormLabel>
                                                                        <Select
                                                                            value={additionalFeedback.get(selectedRun.rationale.requirements[key].name)?.wasRequired || []}
                                                                            onChange={(e) => setAdditionalFeedback((prev) => new Map(prev.set(selectedRun.rationale.requirements[key].name, {
                                                                                ...prev.get(selectedRun.rationale.requirements[key].name),
                                                                                wasRequired: e.target.value,
                                                                            })))}
                                                                            renderValue={(selected) => selected}
                                                                        >
                                                                            <MenuItem value="">None</MenuItem>
                                                                            <MenuItem value="Required">Required</MenuItem>
                                                                            <MenuItem value="Not required">Not required</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                                <TextField
                                                                    fullWidth
                                                                    multiline
                                                                    rows={2}
                                                                    value={additionalFeedback.get(selectedRun.rationale.requirements[key].name)?.additionalFeedback || ''}
                                                                    onChange={(e) => setAdditionalFeedback((prev) => new Map(prev.set(selectedRun.rationale.requirements[key].name, {
                                                                        ...prev.get(selectedRun.rationale.requirements[key].name),
                                                                        additionalFeedback: e.target.value,
                                                                    })))}
                                                                    placeholder="Anything else to add?"
                                                                    sx={{ mb: 2 }}
                                                                />
                                                            </Box>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            <TableRow key={`${key}-description`}>
                                                <TableCell>Description</TableCell>
                                                <TableCell colSpan={2}>
                                                    {selectedRun.rationale.requirements[key]['description']}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key={`${key}-analysis`}>
                                                <TableCell>Analysis</TableCell>
                                                <TableCell colSpan={2}>
                                                    {selectedRun.rationale.requirements[key].details['analysis']}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key={`${key}-reference`}>
                                                <TableCell>Reference</TableCell>
                                                <TableCell colSpan={2}>
                                                    {selectedRun.rationale.requirements[key].details['reference']}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key={`${key}-documentLink`}>
                                                <TableCell>Document Reference</TableCell>
                                                <TableCell colSpan={2}>
                                                    {selectedRun?.rationale?.requirements[key]?.details['reference_files'] ? (selectedRun?.rationale?.requirements[key]?.details['reference_files']?.map((file, index) => (
                                                        <FairwayDocumentDisplay key={index} document={
                                                            {
                                                                name: selectedRun.files[0].filename,
                                                                link: selectedRun.files[0].url,
                                                                pageNumber:  parseInt(file.match(/\d+/)[0], 10) || 1
                                                            }
                                                        } />
                                                    ))): 'No document reference'}
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                            {selectedRun.feedback?.isResultCorrect === false && (
                                <Box sx={{
                                    display: 'flex', justifyContent: 'flex-end', mt: 2, flexDirection:
                                        'column'
                                }}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={2}
                                        value={feedbackText}
                                        onChange={(e) => setFeedbackText(e.target.value)}
                                        placeholder="Anything else to add?"
                                        sx={{ mb: 2 }}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Tooltip title="Save your progress and come back later">
                                            <span>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => handleFeedbackSubmit(false, false)}
                                                >
                                                    Save Progress
                                                </Button>
                                            </span>
                                        </Tooltip>

                                        <Tooltip title="Submit your feedback and finish the review">
                                            <span>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleFeedbackSubmit(false, true)}
                                                >
                                                    Submit Feedback
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </>
        </Modal>
    );
};

export default AvaRationaleModal;