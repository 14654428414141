import React, { useEffect, useState } from 'react'
import { useMediaQuery, Modal, Box, Button, Typography, TextField, CircularProgress } from '@mui/material'
import { useColorMode } from '../../theme'
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
} from 'firebase/auth'
import { auth } from '../../firebaseConfig'
import { useUser } from '../../contexts/UserContext'
import { useNavigate, useLocation } from 'react-router-dom'

import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Logout from '@mui/icons-material/Logout'

const ModalSignIn = ({ signInOpen, setSignInOpen }) => {
  const { theme } = useColorMode()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { user, userError, invitation, setInvitation, company, loadingUserContext, loadingUserInviteData, isLoggedIn } =
    useUser()
  const provider = new GoogleAuthProvider()
  const [modalMessages, setModalMessages] = useState({
    title: 'Welcome to Fairway!',
    subtitle: 'Log in or signup if you are new.',
  })
  const [isSignUp, setIsSignUp] = useState(false)
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const [baseUrl, setBaseUrl] = useState('')

  useEffect(() => {
    const url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`
    setBaseUrl(url)
  }, [location])

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '90%' : '60%',
    height: isMobile ? '94%' : '85%',
    bgcolor: `${theme.palette.background.default}`,
    outline: 'none',
    boxShadow: 24,
    p: 4,
  }

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        setSignInOpen(false)
        navigate('/')
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleSendSignInLink = () => {
    let finishSignIn
    if (invitation?.type === 'companyInvite') {
        finishSignIn = `/companyInvite/${invitation.id}`
    } else if (invitation?.type === 'companyOnboarding') {
        finishSignIn = `/companyOnboarding/${invitation.id}`
    } else {
      finishSignIn = '/finishSignIn'
    }
    const actionCodeSettings = {
      url: baseUrl + finishSignIn, // Update to your app's URL
      handleCodeInApp: true,
    }
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem('emailForSignIn', email)
        setInvitation({ type: 'signInLinkSent' })
        setModalMessages({
          title: 'Check your email!',
          subtitle: 'A sign-in link has been sent to your email.',
        })
      })
      .catch((error) => {})
  }

  useEffect(() => {
    if (user && company) {
      setSignInOpen(false)
      navigate('/')
    }
  }, [user, company])

  useEffect(() => {
    if (userError) {
      setModalMessages({
        title: 'Contact us to access Fairway!',
        subtitle: 'You are not authorized to use our platform. Request access sending an email to info@getfairway.com.',
      })
    } else if (invitation) {
      if (invitation.type === 'companyOnboarding') {
        setModalMessages({
          title: 'You have been invited to Fairway!',
          subtitle: 'Login with the email you have been invited to create your organization.',
        })
      } else if (invitation.type === 'companyInvite') {
        const message = `Signup ` + (invitation.userEmail ? `with ${invitation.userEmail}` : ``)
        setModalMessages({
          title: 'You have been invited to Fairway!',
          subtitle: message,
        })
      }
    }
  }, [invitation, userError])

  useEffect(() => {
    const auth = getAuth()
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        email = window.prompt('Please provide your email for confirmation')
      }
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem('emailForSignIn')
           setSignInOpen(false)
        })
        .catch((error) => {})
    }
  }, [])

  return (
    <Modal
      open={signInOpen}
      onClose={() => setSignInOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        '& > .MuiBackdrop-root': {
          backdropFilter: 'blur(10px)',
        },
      }}
    >
      <Box sx={{ ...modalStyle }}>
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            pt: '0%',
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
            <img
              alt="profile-user"
              width="100"
              height="100"
              src={`/Fwufh6cTORu5.png`}
              style={theme.palette.mode === 'light' ? { filter: 'brightness(0.3) contrast(1.2)' } : {}}
            />
          </Box>
          {loadingUserInviteData && <CircularProgress />}
          {!loadingUserInviteData && loadingUserContext && <CircularProgress />}
          {((!isLoggedIn && !loadingUserInviteData && !loadingUserContext) || userError) && (
            <Box>
              <Typography m="4%" component="h1" variant="h5" align="center">
                {modalMessages.title}
              </Typography>
              <Typography mt="8%" mb="8%" variant="h7" color={theme.palette.success.main} align="center">
                {modalMessages.subtitle}
              </Typography>
            </Box>
          )}
          {!isLoggedIn && !loadingUserInviteData && !userError && !loadingUserContext && (
            <>
              <Box component="form" onSubmit={(e) => e.preventDefault()} sx={{ mt: 3 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleSendSignInLink}
                >
                  {isSignUp ? 'Sign Up' : 'Sign In'}
                </Button>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    my: 2,
                  }}
                >
                  <Box sx={{ flex: 1, height: '1px', bgcolor: 'grey.400' }} />
                  <Typography variant="body2" sx={{ mx: 2 }}>
                    or
                  </Typography>
                  <Box sx={{ flex: 1, height: '1px', bgcolor: 'grey.400' }} />
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleGoogleSignIn}
                  sx={{
                    mt: 3,
                    mb: 2,
                    bgcolor: 'common.white',
                    color: 'grey.900',
                    borderColor: 'grey.400',
                    '&:hover': {
                      bgcolor: 'grey.100',
                    },
                  }}
                  startIcon={<img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Google icon" />}
                >
                  Continue with Google
                </Button>
              </Box>
            </>
          )}
          {!loadingUserInviteData && isLoggedIn && (
            <MenuItem
              onClick={() => {
                signOut(auth)
                  .then(() => {
                    // Sign-out successful.
                  })
                  .catch((error) => {})
              }}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          )}
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalSignIn
