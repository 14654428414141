export const dateSort = (v1, v2) => {
    // Handle null/undefined values: always place them last
    if (v1 === null || v1 === undefined) return -1;
    if (v2 === null || v2 === undefined) return 1;

    // Parse dates and compare them
    const date1 = new Date(v1);
    const date2 = new Date(v2);

    return date1 - date2;
};

export const formatDate = (params) => {
    if (!params) return '';
    const date = new Date(params);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}
