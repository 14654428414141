import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Typography,
} from '@mui/material'
import { Add as AddIcon, ContentCopy as CopyIcon, Delete as DeleteIcon } from '@mui/icons-material'
import {deleteCustomerAPI, fetchCustomersAPI, inviteCustomerAPI} from "../../api/customers";
import {useUser} from "../../contexts/UserContext";
import {inviteUserAPI} from "../../api/users";

const ManageCustomers = () => {
  const [companies, setCompanies] = useState([])
  const [newCompany, setNewCompany] = useState({
    email: '',
    companyName: '',
  }) // State to manage new company input
  const [openModal, setOpenModal] = useState(false) // State for modal visibility
  const [selectedCompany, setSelectedCompany] = useState(null) // State for the selected company to delete
  const [showNewCompanyFields, setShowNewCompanyFields] = useState(false) // State to control visibility of new company fields
  const {user} = useUser()
  const [baseUrl, setBaseUrl] = useState('')

  useEffect(() => {
    const url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`
    setBaseUrl(url)
  }, [location])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedCompanies = await fetchCustomersAPI().then(response => response.json())
        console.log('Fetched companies:', fetchedCompanies)
        setCompanies(fetchedCompanies)
      } catch (error) {
        console.error('Error fetching companies: ', error)
      }
    }

    fetchData()
  }, [])

  // Handle changes to new company input fields
  const handleNewCompanyChange = (e) => {
    const { name, value } = e.target
    setNewCompany((prev) => ({ ...prev, [name]: value }))
  }

  // Email validation function
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  // Add a new company and save it to Firestore
  const handleAddRow = async () => {
    if (!showNewCompanyFields) {
      // Show the input fields if not already visible
      setShowNewCompanyFields(true)
    } else {
      // Validate email before proceeding
      if (!isValidEmail(newCompany.email)) {
        alert('Please enter a valid email address.')
        return
      }

      try {
        newCompany.email = newCompany.email.toLowerCase()
        const newCustomer = await inviteCustomerAPI(newCompany.companyName, newCompany.email, user.id).then(response => response.json())
        if (!newCustomer?.id) {
            throw new Error('Error saving new company')
        }
        await inviteUserAPI(newCustomer.id, newCompany.email, user.id, 'ADMIN',false, true).then(response => response.json());
        // Add new company to local state after successful save
        setCompanies((prev) => [...prev, newCustomer])
        alert('New company created successfully.')
      } catch (error) {
        console.error('Error saving new company: ', error)
      }
      setNewCompany({ email: '', companyName: '' }) // Reset input fields
      setShowNewCompanyFields(false) // Hide input fields after adding
    }
  }

  // Open the delete confirmation modal
  const handleRemoveRow = (company) => {
    setSelectedCompany(company)
    setOpenModal(true)
  }

  // Confirm deletion of a company
  const handleConfirmDelete = async () => {
    try {
      await deleteCustomerAPI(selectedCompany.id)
      setCompanies(companies.filter((company) => company.id !== selectedCompany.id))
      alert('Company deleted successfully.')
    } catch (error) {
      console.error('Error deleting company: ', error)
    }
    setOpenModal(false)
    setSelectedCompany(null)
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <h2>Create New Company Invite</h2>
        {/* Button to add a new row for a company and main user */}
        <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddRow}>
          {showNewCompanyFields ? 'Save New Company Invite' : 'Add Company Invite'}
        </Button>
      </Box>

      {/* Conditionally render input fields for adding a new company */}
      {showNewCompanyFields && (
        <Box display="flex" alignItems="center" mb={2}>
          <TextField
            label="Email of Company Creator"
            name="email"
            value={newCompany.email}
            onChange={handleNewCompanyChange}
            variant="outlined"
            sx={{ marginRight: 2, width: '45%' }}
          />
          <TextField
            label="Company Name"
            name="companyName"
            value={newCompany.companyName}
            onChange={handleNewCompanyChange}
            variant="outlined"
            sx={{ marginRight: 2, width: '45%' }}
          />
        </Box>
      )}

      {/* Table to display existing companies */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell>Email of Company Creator</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company, index) => (
              <TableRow key={company.id}>
                <TableCell>{company.name}</TableCell>
                <TableCell>{company.email}</TableCell>
                <TableCell>{new Date(company.created_at).toLocaleDateString()}</TableCell>
                <TableCell>
                  {company.invite_accepted === false && (
                      <>
                        <IconButton onClick={() => handleRemoveRow(company)} color="error">
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => navigator.clipboard.writeText(`${baseUrl}/companyOnboarding/${company.id}`)}
                            aria-label="copy"
                        >
                          <CopyIcon />
                        </IconButton>
                      </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for delete confirmation */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="delete-confirmation-title"
        aria-describedby="delete-confirmation-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="delete-confirmation-title" variant="h6" component="h2">
            Are you sure you want to delete this company?
          </Typography>
          <Typography id="delete-confirmation-description" sx={{ mt: 2 }}>
            Only companies that has not accepted the invitation yet can be deleted.
          </Typography>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="outlined" onClick={() => setOpenModal(false)} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default ManageCustomers
