import React, { useState, useEffect, useCallback } from 'react'
import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Modal,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Autocomplete,
  useMediaQuery,
  Tooltip,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@mui/material'
import CustomDataGrid from '../../components/CustomDataGrid'
import { useColorMode } from '../../theme'
import { useUser } from '../../contexts/UserContext'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FeedbackModal from '../../components/FeedbackModal'
import ConfirmationModal from '../../components/ConfirmationModal'
import FileUpload from '../global/FileUpload'
import { addAuditorRun, subscribeToAuditorRuns, batchArchiveAuditorRuns, batchUnarchiveAuditorRuns, batchDeleteAuditorRuns } from '../../database/services/avaAuditor'
import { postAvaAuditorRun } from '../../api/avaAuditor'
import { updateDocument, uploadFile } from '../../database/databaseUtils'
import { startAvaAuditorRun } from '../../api/fetchFairway'
import { fetchUsersAPI } from '../../api/users'
import { where } from 'firebase/firestore'
import FairwaySearchBar from '../../components/FairwaySearchBar'
import { dateSort, formatDate } from '../../helpers/dateHelper'
import { actionIcons, correctAnswerOptions, rejectionReasonOptions } from './constants/avaAuditorConstants'
import DocumentsModal from '../../components/DocumentsModal'
import AvaRationaleModal from '../../components/AvaRationaleModal'
import FairwayChip from '../../components/FairwayChip'

const AvaAuditor = () => {
  const { theme } = useColorMode()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { user, company } = useUser()
  const [auditorRuns, setAuditorRuns] = useState([])
  const [loading, setLoading] = useState(true)
  const [operationInProgress, setOperationInProgress] = useState(false)
  const [formOpen, setFormOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const steps = ['Upload documents']
  const [allFiles, setAllFiles] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [documentsModalOpen, setDocumentsModalOpen] = useState(false)
  const [rationaleModalOpen, setRationaleModalOpen] = useState(false)
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [confirmationMessage, setConfirmationMessage] = useState('')
  const [selectedRun, setSelectedRun] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [actionFunction, setActionFunction] = useState(async () => { })
  const [runFilter, setRunFilter] = useState(where('status', '!=', 'Archived'))
  const [selectedOption, setSelectedOption] = useState('current-cases')
  const [columnWidths, setColumnWidths] = useState(new Map())
  const [queriedRunsList, setQueriedRunsList] = useState([])
  const [searchText, setSearchText] = useState('')
  const [users, setUsers] = useState([])
  const [checkedRuns, setCheckedRuns] = useState([])

  const handleColumnResize = (params) => {
    setColumnWidths((prev) => {
      const newMap = new Map(prev)
      newMap.set(params.colDef.field, params.width)
      return newMap
    })
  }
  const getUsers = async () => {
    try {
      const data = await fetchUsersAPI(company.new_id).then(response => response.json())
      setUsers(data)
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }

  const getStatus = (row) => {

    switch (row?.status) {
      case 'Done':
        if(row.feedback && row.feedback.isFinal) {
          return 'Reviewed'
        } else if(row.feedback && !row.feedback.isFinal) {
          return 'Review in progress'
        }
        return 'Ready for review'
      case 'Failed':
      case 'Error':
        return 'Failed'
      case 'In Progress':
      case 'Created':
        return 'In Progress'
      default:
        return 'In Progress'
    }
  }

  const getStatusColor = (row) => {
    switch (row?.status) {
      case 'Done':
        if(row.feedback && row.feedback.isFinal) {
          return 'success'
        } else if(row.feedback && !row.feedback.isFinal) {
          return 'warning'
        }
        return 'information'
      case 'Failed':
      case 'Error':
        return 'error'
      case 'In Progress':
      case 'Created':
        return 'warning'
      default:
        return 'information'
    }
  }


  const ThreeDotMenu = ({ options, onOptionClick }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const handleMenuItemClick = (option) => {
      onOptionClick(option)
      handleClose()
    }

    return (
      <div>
        <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {options.map((option, index) => (
            <MenuItem key={index} onClick={() => handleMenuItemClick(option)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }

  const auditorRunsColumns = [
    {
      field: 'run_datetime',
      headerName: 'Run Time',
      width: columnWidths.get('run_datetime') || 120,
      headerAlign: 'center',
      sortComparator: (v1, v2) => {
        return dateSort(v1, v2)
      },
      valueFormatter: (params) => {
        return formatDate(params)
      },
    },
    {
      field: 'organization_name',
      headerName: 'Organization Name',
      width: columnWidths.get('organization_name') || 200,
      headerAlign: 'center',
      searchable: true,
    },
    {
      field: 'approved',
      headerName: 'Decision',
      width: columnWidths.get('approved') || 130,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          {params.row.approved && (
          <FairwayChip
            displayText={params.row.approved === 'Approved' ? 'Approved' : 'Rejected'}
            color={params.row.approved === 'Approved' ? 'success' : 'error'}

          />
        )}
        </Box>
      ),
    },
    {
      field: 'utilities',
      headerName: 'Utilities',
      width: columnWidths.get('utilities') || 110,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box display="flex">
          {Object.keys(actionIcons).map((action) => (
            <Tooltip key={action} title={actionIcons[action].tooltip}>
              <span>
                <IconButton
                  onClick={() => handleAction(action, params.row)}
                  disabled={
                    (action === 'rationale' && !params.row.rationale)
                  }
                >
                  {actionIcons[action].icon}
                </IconButton>
              </span>
            </Tooltip>
          ))}
        </Box>
      ),
    },
    {
      field: 'state',
      headerName: 'State/County',
      width: columnWidths.get('state') || 200,
      headerAlign: 'center',
      searchable: true,
    },
    {
      field: 'user_name',
      headerName: 'User Name',
      width: columnWidths.get('user_name') || 170,
      headerAlign: 'center',
      searchable: true,
      renderCell: (params) => {
        return (
          users.length > 0 ? (
            <FormControl fullWidth>
              <Select
                value={params.value || ''}
                onChange={(event) => handleUserChange(event, params.row)}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              >
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.name}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Box display="flex" alignItems="center">
              <Typography variant="body2">{params.value}</Typography>
            </Box>
          )
        )
      },
    },
    {
      field: 'vin',
      headerName: 'VIN',
      width: columnWidths.get('vin') || 190,
      headerAlign: 'center',
      searchable: true,
    },

    {
      field: 'status',
      headerName: 'Status',
      width: columnWidths.get('status') || 200,
      headerAlign: 'center',
      filterable: false,
      valueGetter: (value, row) => ({
        value: getStatus(row),
        row
      }),
      sortComparator: (v1, v2) => {
        return getStatus(v1.row).localeCompare(getStatus(v2.row));
      },
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center" mt="15px">
            <FairwayChip
              displayText={getStatus(params?.row)}
              color={getStatusColor(params?.row)}
            />
            {(getStatus(params?.row) === 'Failed' || getStatus(params?.row) === 'Error') && params.row.files[0]['gsUrl'] && (
              
              <Button size="small" onClick={() => handleAction('retryFailedRun', params.row)} sx={{ marginLeft: 1 }}>
                Retry
              </Button>
            )}
          </Box>
        );
      },
    },
    {
      field: 'name',
      headerName: 'File Name',
      width: columnWidths.get('name') || 150,
      headerAlign: 'center',
      searchable: true,
    },
    {
      field: 'options',
      headerName: '',
      width: columnWidths.get('options') || 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const options =
          params.row.status == 'Archived' ? ['Unarchive', 'Delete'] : ['Archive', 'Delete']
        const handleOptionClick = (option) => {
          if (option === 'Archive') {
            handleAction('archiveRun', params.row)
          } else if (option === 'Unarchive') {
            handleAction('unarchiveRun', params.row)
          } else if (option === 'Delete') {
            handleAction('deleteRun', params.row)
          }
        }
        return (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <ThreeDotMenu options={options} onOptionClick={handleOptionClick} />
          </Box>
        )
      },
    },
  ]

  const handleUserChange = async (event, row) => {
    await updateDocument(['customers', company.id, 'tools', 'AvaAuditor', 'runs'], row.id, {
      user_name: event.target.value,
    })
  }
  const handleAction = (action, row) => {
    switch (action) {
      case 'viewDocument':
        handleDocumentsView(row)
        break
      case 'rationale':
        handleRationaleView(row)
        break
      case 'leaveFeedback':
      case 'archiveRun':
      case 'unarchiveRun':
      case 'deleteRun':
        handleActionView(action, row)
        break
      case 'retryFailedRun':
        handleRetryAuditorRun(row)
        break
      default:
        break
    }
  }

  const handleActionView = (action, row) => {
    const newCheckedRuns = [...checkedRuns]
    switch (action) {
      case 'leaveFeedback':
        setSelectedRun(row)
        setFeedbackModalOpen(true)
        break

      case 'archiveRun':
        setSelectedRun(row)
        if (!checkedRuns.some((checkedRun) => checkedRun.id === row.id)) {
          newCheckedRuns.push(row)
          setCheckedRuns(newCheckedRuns)
        }
        archiveSelection(newCheckedRuns, true)
        break

      case 'unarchiveRun':
        setSelectedRun(row)
        if (!checkedRuns.some((checkedRun) => checkedRun.id === row.id)) {
          newCheckedRuns.push(row)
          setCheckedRuns(newCheckedRuns)
        }
        archiveSelection(newCheckedRuns, false)
        break

      case 'deleteRun':
        setSelectedRun(row)
        if (!checkedRuns.some((checkedRun) => checkedRun.id === row.id)) {
          newCheckedRuns.push(row)
          setCheckedRuns(newCheckedRuns)
        }
        deleteSelection(newCheckedRuns)
        break

      default:
        console.error(`Action not implemented: ${action}`)
        alert('Action not implemented')
    }
  }

  const handleFeedbackCloseModal = () => {
    setFeedbackModalOpen(false)
    setSelectedRun(null)
  }

  const handleConfirmationCloseModal = () => {
    setConfirmationModalOpen(false)
    setSelectedRun(null)
    setActionFunction(null)
  }

  const handleRationaleView = (row) => {
    setSelectedRun(row)
    setRationaleModalOpen(true)
  }

  const handleRationaleCloseModal = () => {
    setRationaleModalOpen(false)
    setSelectedRun(null)
  }

  const handleDocumentsView = (row) => {
    setSelectedRun(row)
    setDocumentsModalOpen(true)
  }

  const handleDocumentsCloseModal = () => {
    setDocumentsModalOpen(false)
    setSelectedRun(null)
  }

  const handleFilterChange = (event) => {
    setSelectedOption(event.target.value)
    setLoading(true)

    if (event.target.value === 'current-cases')
      return setRunFilter(where('status', '!=', 'Archived'))

    return setRunFilter(where('status', '==', 'Archived'))
  }



  const filterAndSearch = (searchText = '', runs = queriedRunsList) => {
    const filteredRuns = runs.filter((run) => {
      // Check if the search query matches any of the columns with the searchable property
      return auditorRunsColumns.some((col) => {
        if (col.searchable) {
          const value = run[col.field];
          return value?.toString().toLowerCase().includes(searchText.toLowerCase());
        }
        return false;
      });
    });

    setAuditorRuns(filteredRuns);
  };
  useEffect(() => {
    if (!company) return

    // Subscribe to the runs collection
    const unsubscribe = subscribeToAuditorRuns(
      company.id,
      runFilter,
      async (runsList) => {
        // Handle the updated runs list
        const formattedRunsList = runsList.map((run) => ({
          ...run,
          status: run.status === 'Done Successfully' ? 'Done' : run.status,
          run_datetime: new Date(run.run_datetime.seconds * 1000).toLocaleString(),
          state:
            run.rationale?.location?.county && run.rationale?.location?.state
              ? run.rationale?.location?.state + '/' + run.rationale?.location?.county
              : !isNaN(run.rationale?.location?.state + run.rationale?.location?.county)
                ? run.rationale?.location?.state + run.rationale?.location?.county
                : '',
          approved: run.approved == undefined ? '' : run.approved ? 'Approved' : 'Rejected',
          vin: run.rationale?.vin,
          organization_name: run.rationale?.organization_name,
        }));
        setQueriedRunsList(formattedRunsList)
        filterAndSearch(searchText, formattedRunsList)
        await getUsers()
        setLoading(false)
      },
      (error) => {
        console.error('Error fetching runs data:', error)
        setLoading(false)
      }
    )

    return () => unsubscribe()
  }, [company.id, runFilter, searchText])

  const handleFormClose = () => {
    setIsSubmitting(false)
    setFormOpen(false)
    setAllFiles([])
    setSelectedFiles([])
    setInputValue('')
    setActiveStep(0)
  }

  const handleFileUpload = async ({ url, gsUrl, filename, docId, size, type }) => {
    const newFile = {
      ...(await uploadFile({
        companyId: company.id,
        toolName: 'AvaAuditor',
        url,
        gsUrl,
        filename,
        docId,
        uploadedBy: user.name,
        uploadedAt: new Date(),
      })),
      size,
      type,
    }
    setAllFiles((prevState) => [...prevState, newFile])
    setSelectedFiles((prevState) => [...prevState, newFile])
  }

  const handleNext = () => {
    if (activeStep === 0 && !selectedFiles.length) return
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleAddAuditorRun = async () => {
    setIsSubmitting(true)
    setFormOpen(false)

    const runData = {
      name: !inputValue ? selectedFiles[0].filename : inputValue,
      customer_id: company.id,
      status: 'Created',
      run_datetime: new Date(),
      user_name: user.name,
      user_id: user.firebase_uid,
      files: selectedFiles.map((file) => ({
        filename: file.filename,
        url: file.url,
        gsUrl: file.gsUrl,
        uploadedBy: file.uploadedBy,
        uploadedAt: file.uploadedAt,
      })),
    }
    // Use the utility function to add the Auditor Run
    const runDocRef = await addAuditorRun({
      ...runData,
    })
    try {
      if (!runDocRef) {
        console.error('Failed to create auditor run document.')
        return
      }

      // Use the utility function to update each file
      for (const file of selectedFiles) {
        await updateDocument(['customers', company.id, 'tools', 'AvaAuditor', 'files'], file.id, {
          run_id: runDocRef.id,
        })
      }

      const postData = {
        name: !inputValue ? selectedFiles[0].filename : inputValue,
        status: 'Created',
        user_id: user.id,
        email: user.email.toLowerCase(), //TODO Remove when user_id is used
        files: selectedFiles.map((file) => ({
          name: file.filename,
          size: file.size,
          type: file.type,
          download_url: file.url,
          gs_url: file.gsUrl,
        })),
        customer_name: company.name, //TODO Remove when customer_id is used
      }
      const coreResponse = await postAvaAuditorRun(user.customer_new_id, postData) //TODO Replace 0 with customer_id once customer_id is used
      const coreData = await coreResponse.json()

      if (!coreResponse.ok) {
        console.log('Failed to run AvaAuditor core')
      }

      const requestData = {
        user_id: user.firebase_uid,
        customer_id: company.id,
        tool_run_id: runDocRef.id,
        input_images: selectedFiles,
        core_run_id: coreData.id, // TODO will replace the tool_run_id
      }

      // TODO: temporary fix until we refactor the services to return a promise.
      handleFormClose()

      const response = await startAvaAuditorRun(requestData)
      const data = await response.json()

      // TODO: we could centralize this error handling to avoid repeating it in every tool.
      if (response.ok) {
        // TODO: when the error handling is centralized, we can take the opportunity to use
        //       a popup instead of an alert for both success and error messages.
        alert(`Success: ${data.message}`)
      } else {
        throw new Error(data.message || 'Failed to start AvaAuditor run')
      }
    } catch (error) {
      console.error('Error processing request: ', error)
      await updateDocument(['customers', company.id, 'tools', 'AvaAuditor', 'runs'], runDocRef.id, {
        status: 'Failed',
        result: '',
      })
    }
  }

  const handleRetryAuditorRun = async (row) => {
    try {
      const requestData = {
        user_id: row.user_id,
        customer_id: row.customer_id,
        tool_run_id: row.id,
        input_images: row.files,
      }

      const response = await startAvaAuditorRun(requestData)
      const data = await response.json()

      // TODO: we could centralize this error handling to avoid repeating it in every tool.
      if (response.ok) {
        // TODO: when the error handling is centralized, we can take the opportunity to use
        //       a popup instead of an alert for both success and error messages.
        alert(`Success: ${data.message}`)
      } else {
        throw new Error(data.message || 'Failed to start AvaAuditor run')
      }
    } catch (error) {
      console.error('Error processing request: ', error)
      await updateDocument(['customers', company.id, 'tools', 'AvaAuditor', 'runs'], row.id, {
        status: 'Failed',
        result: '',
      })
    }
  }

  const handleSelectDocument = useCallback((event, values) => {
    setSelectedFiles(values)
  }, [])

  const handleInputChange = useCallback((e) => {
    setInputValue(e.target.value)
  }, [])

  const onSearchChange = useCallback((searchText) => {
    setSearchText(searchText)
    if (queriedRunsList.length > 0) {
      filterAndSearch(searchText)
    }
  }, [queriedRunsList])

  const archiveSelection = useCallback(async (runs, archive) => {
    setConfirmationMessage(`Are you sure you want to ${archive ? 'archive' : 'unarchive'} the selected runs?`)
    setActionFunction(async () => async () => {
      setOperationInProgress(true)
      const runIds = runs.map((run) => run.id)
      if (archive) {
        await batchArchiveAuditorRuns(company.id, runIds)
      } else {
        await batchUnarchiveAuditorRuns(company.id, runIds)
      }
      setCheckedRuns([])
      setConfirmationModalOpen(false)
      setOperationInProgress(false)
    })
    setConfirmationModalOpen(true)
  }, [company.id])

  const deleteSelection = useCallback(async (runs) => {
    setConfirmationMessage('Are you sure you want to delete the selected runs?')
    setActionFunction(async () => async () => {
      setOperationInProgress(true)
      const runIds = runs.map((run) => run.id)
      await batchDeleteAuditorRuns(company.id, runIds)
      setCheckedRuns([])
      setConfirmationModalOpen(false)
      setOperationInProgress(false)
    })
    setConfirmationModalOpen(true)
  }, [company.id])


  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <FormControl variant="outlined" sx={{ minWidth: 150, ml: 2 }} style={{ marginLeft: 0 }}>
              <InputLabel id="runs-select-label">Filter</InputLabel>
              <Select
                labelId="runs-select-label"
                value={selectedOption}
                onChange={handleFilterChange}
                label="Filter"
                style={{ fontSize: '0.875rem' }}
              >
                <MenuItem value="current-cases">Current Cases</MenuItem>
                <MenuItem value="archived">Archived</MenuItem>
              </Select>
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  '@media (max-width: 600px)': {
                    display: 'none'
                  }
                }}
              >
                <FairwaySearchBar
                  searchQuery={searchText}
                  setSearchQuery={(searchText) => onSearchChange(searchText)}
                />
              </Box>

            </Box>
            <Button variant="</MenuItem>contained" startIcon={<AddIcon />} onClick={() => setFormOpen(true)}>
              Add
            </Button>
          </Box>
          <Box
            sx={{
              minHeight: 400,
              width: '100%',
              overflowX: 'auto',
              overflowY: 'auto',
              paddingBottom: '0px',
              '&::-webkit-scrollbar': {
                width: '12px',
                height: '12px',
              },
              '&::-webkit-scrollbar-track': {
                background: theme.palette.background.secondary,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.background.primary,
                borderRadius: '10px',
                border: `3px solid ${theme.palette.background.secondary}`,
              },
              '& *': {
                scrollbarWidth: 'thin',
                scrollbarColor: `${theme.palette.background.primary} ${theme.palette.background.secondary}`,
              },
            }}
          >
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            ) : (
            <CustomDataGrid
              rows={auditorRuns}
              columns={auditorRunsColumns}
              pageSize={25}
              loading={loading}
              disableRowSelectionOnClick={true}
              autoHeight
              onColumnResize={handleColumnResize}
              rowsSelectable={true}
              selectedRows={checkedRuns}
              onRowsSelected={(rows) => setCheckedRuns(rows)}
              initialSortModel={[{ field: 'run_datetime', sort: 'desc' }]}
            />)}
          </Box>
        </Grid>
      </Grid>

      <Modal open={formOpen} onClose={handleFormClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '90%' : '50%',
            maxHeight: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h6">Add Ava Auditor Run</Typography>
            <IconButton onClick={handleFormClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={
                index
              }>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  {index === 0 && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Autocomplete
                          multiple
                          disablePortal
                          id="documents-select"
                          options={allFiles}
                          value={selectedFiles}
                          getOptionLabel={(option) => option.filename}
                          onChange={handleSelectDocument}
                          renderInput={(params) => (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <TextField {...params} label="Select documents" sx={{ flexGrow: 1 }} />
                            </Box>
                          )}
                        />
                        <TextField
                          label="Optional name"
                          name="name"
                          defaultValue={inputValue}
                          onBlur={(e) => setInputValue(e.target.value)}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FileUpload
                          user={user}
                          multiple={true}
                          onSuccess={({ url, gsUrl, filename, docId, size, type }) =>
                            handleFileUpload({ url, gsUrl, filename, docId, size, type })
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={!selectedFiles.length}
                      >
                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                      </Button>
                      <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>All steps completed - you're finished</Typography>
              <Button onClick={handleFormClose} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                  disabled={isSubmitting}
                  onClick={handleAddAuditorRun}
                >
                  Add Run
                </Button>
                {isSubmitting && <CircularProgress size={24} sx={{ ml: 2 }} />}
              </Box>
            </Paper>
          )}
        </Box>
      </Modal>

      <AvaRationaleModal
        open={rationaleModalOpen}
        onClose={handleRationaleCloseModal}
        selectedRun={selectedRun}
      />

      <FeedbackModal
        open={feedbackModalOpen}
        onClose={handleFeedbackCloseModal}
        tool="AvaAuditor"
        selectedRun={selectedRun}
        showCorrectAnswerDropdown={true}
        correctAnswerOptions={correctAnswerOptions}
        showRejectionReasons={true}
        rejectionReasonOptions={rejectionReasonOptions}
        showAdditionalFeedback={true}
        shouldMakeFetch={true}
      />

      <ConfirmationModal
        open={confirmationModalOpen}
        onClose={handleConfirmationCloseModal}
        handleConfirm={actionFunction}
        selectedRun={selectedRun}
        confirmationMessage={confirmationMessage}
        inProgress={operationInProgress}
      />

      <DocumentsModal
        documentsModalOpen={documentsModalOpen}
        handleDocumentsCloseModal={handleDocumentsCloseModal}
        selectedRun={selectedRun}
      />

    </Box>
  )
}

export default AvaAuditor
