import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';

const FairwayDocumentDisplay = ({ document }) => {
    const { name, link, extension, pageNumber } = document;

    const openDocument = () => {
        window.open(`${link}#page=${pageNumber}`, '_blank');
    };

    return (
        <Box onClick={openDocument} sx={{
            cursor: 'pointer', 
            justifyContent: 'space-between',
            display: 'flex', 
            alignItems: 'center',
            border: '0.5px solid #2B2E33', 
            borderRadius: '8px', 
            padding: '12px 16px', 
            gap: '6px',
            color: 'white',
            width: 'auto',
            height: '42px',
        }}>
            <Typography
                variant="body1"
                noWrap
                sx={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
                {name}
            </Typography>
            <Typography variant="body2" sx={{ marginLeft: '10px' }}>
                {extension}
            </Typography>
            <DescriptionIcon 
            />
        </Box>
    );
};

export default FairwayDocumentDisplay;