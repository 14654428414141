import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import {fetchGroups, updateVehicleGroup, createGroup, updateGroup} from '../../database/services/groups'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField
} from '@mui/material'
import { LanguagesPreferences } from './languagesPreferences'
import {fetchVehicles, fetchVehiclesByGroupId} from "../../database/services/vehicles";
import {getOrganizations} from "../../database/services/organization";
import {getAgentsInformation} from "../../database/services/settings";
import Header from "../../components/Header";

const GroupEdit = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useUser()
  const [group, setGroup] = useState(null)
  const [isEditing, setIsEditing] = useState(location.pathname.split('/')[2] === 'new')

  const [value, setValue] = useState(0)
  const [groupId, setGroupId] = useState(location.pathname.split('/')[2] || null)
  const [loadingGroup, setLoadingGroup] = useState(true)
  const [organizations, setOrganizations] = useState([])
  const [vehicles, setVehicles] = useState([])
  const [selectedVehicles, setSelectedVehicles] = useState([])
  const [prevSelectedVehicles, setPrevSelectedVehicles] = useState([])
  const [showUnassignedOnly, setShowUnassignedOnly] = useState(true)
  const [states, setStates] = useState([])

  // Determine the title and subtitle based on whether editing an existing group or creating a new one
  const title = groupId === 'new' ? 'Create New Group' : 'Edit Group'
  const subtitle =
      groupId === 'new'
          ? 'Create a new group for each operational center.'
          : `Edit the details for the ${group?.name || 'selected'} group.`


  useEffect(() => {
    const fetchGroupData = async (groupId) => {
      try {
        const groupsData = await fetchGroups(user.customer_id)
        const groupToEdit = groupsData.find((group) => group.id === groupId)
        setGroup(groupToEdit)

        // Fetch and set vehicles associated with this group
        const vehiclesSnapshot = fetchVehiclesByGroupId(user.customer_id, groupId)
        const selectedVehicleIds = vehiclesSnapshot.docs.map((doc) => doc.id)
        setSelectedVehicles(selectedVehicleIds)
        setPrevSelectedVehicles(selectedVehicleIds)
      } catch (error) {
        console.error('Error fetching group data: ', error)
      } finally {
        setLoadingGroup(false)
      }
    }

    if (groupId !== 'new') {
      fetchGroupData(groupId)
    } else {
      setLoadingGroup(false)
    }
  }, [groupId, user.customer_id])

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const orgsSnapshot = getOrganizations(user.customer_id)
        setOrganizations(orgsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
      } catch (error) {
        console.error('Error fetching organizations: ', error)
      }
    }

    const getVehicles = async () => {
      try {
        const vehicles = await fetchVehicles(user.customer_id)

        const vehiclesWithStatus = vehicles.map((vehicleData) => {
          let groupStatus
          if (!vehicleData.groupID) {
            groupStatus = 'notAssigned'
          } else if (vehicleData.groupID === groupId) {
            groupStatus = 'assignedToThisGroup'
          } else {
            groupStatus = 'assignedToOtherGroup'
          }

          return {
            ...vehicleData,
            groupStatus,
          }
        })

        // Sort vehicles by groupStatus in the desired order
        const sortedVehicles = vehiclesWithStatus.sort((a, b) => {
          const order = ['notAssigned', 'assignedToThisGroup', 'assignedToOtherGroup']
          return order.indexOf(a.groupStatus) - order.indexOf(b.groupStatus)
        })

        setVehicles(sortedVehicles)
      } catch (error) {
        console.error('Error fetching vehicles: ', error)
      }
    }

    fetchOrganizations()
    getVehicles()
  }, [user.customer_id])

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const statesData = await getAgentsInformation()
        setStates(statesData)
      } catch (error) {
        console.error('Error fetching states: ', error)
      }
    }
    fetchStates()
  }, [])


  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleEditClick = async () => {
    if (isEditing) {
      if (groupId === 'new') {
        if (!group?.name) {
          console.error('Name is required to create a new group document.')
          return
        }
        try {
          setLoadingGroup(true)
          const selectedOrganization = organizations.find((org) => org.id === group.organization)
          const newGroup = {
            ...group,
            organizationName: selectedOrganization ? selectedOrganization.name : '',
          }
          const newDocRef = await createGroup(user.customer_id, newGroup)
          setGroupId(newDocRef.id)
          setIsEditing(false)

          // Update selected vehicles with the new group ID and group name
          await Promise.all(
              selectedVehicles.map((vehicleId) =>
                  updateVehicleGroup(user.customer_id, vehicleId, newDocRef.id, group.name)
              )
          )

          // Update deselected vehicles to null
          const deselectedVehicles = prevSelectedVehicles.filter((id) => !selectedVehicles.includes(id))
          await Promise.all(
              deselectedVehicles.map((vehicleId) =>
                  updateVehicleGroup(user.customer_id, vehicleId, null, null)
              )
          )

          navigate(`/vehicles/groups-places`)
        } catch (error) {
          console.error('Error creating new group document: ', error)
        } finally {
          setLoadingGroup(false)
        }
      } else {
        try {
          setLoadingGroup(true)
          const selectedOrganization = organizations.find((org) => org.id === group.organization)
          const updatedGroup = {
            ...group,
            organizationName: selectedOrganization ? selectedOrganization.name : '',
          }
          await updateGroup(user.customer_id, groupId, updatedGroup)

          // Update selected vehicles with the group ID and group name
          await Promise.all(
              selectedVehicles.map((vehicleId) =>
                  updateVehicleGroup(user.customer_id, vehicleId, groupId, group.name)
              )
          )

          // Update deselected vehicles to null
          const deselectedVehicles = prevSelectedVehicles.filter((id) => !selectedVehicles.includes(id))
          await Promise.all(
              deselectedVehicles.map((vehicleId) =>
                  updateVehicleGroup(user.customer_id, vehicleId, null, null)
              )
          )

          navigate(`/vehicles/groups-places`)
        } catch (error) {
          console.error('Error saving group information: ', error)
        } finally {
          setLoadingGroup(false)
        }
      }
    } else {
      setIsEditing(true)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setGroup({ ...group, [name]: value })
  }

  const handleToggleShowVehicles = () => {
    setShowUnassignedOnly(!showUnassignedOnly)
  }

  const handleVehicleSelect = (vehicleId) => {
    setSelectedVehicles((prevState) =>
        prevState.includes(vehicleId) ? prevState.filter((id) => id !== vehicleId) : [...prevState, vehicleId]
    )
  }

  const filteredVehicles = showUnassignedOnly ? vehicles.filter((vehicle) => !vehicle.groupID) : vehicles

  return (
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <IconButton onClick={() => navigate('/vehicles/groups-places')}>
            <ArrowBackIcon />
          </IconButton>
          <Box display="flex" alignItems="center">
            {loadingGroup && <CircularProgress size={24} sx={{ mr: 2 }} />}
            <Button variant="contained" color="primary" onClick={handleEditClick} disabled={!group?.name || loadingGroup}>
              {isEditing ? 'Save' : 'Edit'}
            </Button>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title={title} subtitle={subtitle} />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: 'divider', minWidth: 200 }}
          >
            <Tab label="Basic Information" id={`vertical-tab-0`} aria-controls={`vertical-tabpanel-0`} />
            <Tab label="Vehicle Selection" id={`vertical-tab-1`} aria-controls={`vertical-tabpanel-1`} />
            <Tab label="Contact" id={`vertical-tab-2`} aria-controls={`vertical-tabpanel-2`} />
          </Tabs>
          <Box sx={{ flexGrow: 1, p: 3, bgcolor: 'background.paper' }}>
            <Box role="tabpanel" hidden={value !== 0} id={`vertical-tabpanel-0`} aria-labelledby={`vertical-tab-0`}>
              {value === 0 && (
                  <>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                          label="Name"
                          name="name"
                          value={group?.name || ''}
                          onChange={handleInputChange}
                          InputProps={{ readOnly: !isEditing }}
                          variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                          label="Description"
                          name="description"
                          value={group?.description || ''}
                          onChange={handleInputChange}
                          InputProps={{ readOnly: !isEditing }}
                          variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                          label="Street Address 1"
                          name="streetAddress1"
                          value={group?.streetAddress1 || ''}
                          onChange={handleInputChange}
                          InputProps={{ readOnly: !isEditing }}
                          variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                          label="Street Address 2"
                          name="streetAddress2"
                          value={group?.streetAddress2 || ''}
                          onChange={handleInputChange}
                          InputProps={{ readOnly: !isEditing }}
                          variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                          label="City"
                          name="city"
                          value={group?.city || ''}
                          onChange={handleInputChange}
                          InputProps={{ readOnly: !isEditing }}
                          variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel id="state-label">State</InputLabel>
                      <Select
                          labelId="state-label"
                          id="state-select"
                          name="state"
                          value={group?.state || ''}
                          onChange={handleInputChange}
                          inputProps={{ readOnly: !isEditing }}
                          variant="outlined"
                      >
                        {states.map((state) => (
                            <MenuItem key={state} value={state}>
                              {state}
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                          label="Organizational Legal Name (Optional)"
                          name="organizationName"
                          value={group?.organizationName || ''}
                          onChange={handleInputChange}
                          InputProps={{ readOnly: !isEditing }}
                          variant="outlined"
                          placeholder="Enter the organization's legal name if different"
                      />
                    </FormControl>
                  </>
              )}
            </Box>
            <Box role="tabpanel" hidden={value !== 1} id={`vertical-tabpanel-1`} aria-labelledby={`vertical-tab-1`}>
              {value === 1 && (
                  <>
                    <Button variant="contained" color="primary" onClick={handleToggleShowVehicles}>
                      {groupId === 'new'
                          ? showUnassignedOnly
                              ? 'Show All Vehicles'
                              : 'Show Unassigned Vehicles Only'
                          : showUnassignedOnly
                              ? 'Show All Vehicles'
                              : "Show This Group's Vehicles"}
                    </Button>
                    {(groupId === 'new'
                            ? showUnassignedOnly
                                ? vehicles.filter((vehicle) => !vehicle.groupID)
                                : vehicles
                            : showUnassignedOnly
                                ? vehicles.filter((vehicle) => vehicle.groupID === groupId)
                                : vehicles
                    ).map((vehicle) => {
                      const { vinDecode = {} } = vehicle
                      const { Make = '', Model = '', ModelYear = '' } = vinDecode
                      return (
                          <Box key={vehicle.id} display="flex" alignItems="center">
                            <FormControlLabel
                                control={
                                  <Checkbox
                                      checked={selectedVehicles.includes(vehicle.id)}
                                      onChange={() => handleVehicleSelect(vehicle.id)}
                                      name={vehicle.id}
                                      color="primary"
                                      disabled={!isEditing}
                                  />
                                }
                                label={`${
                                    vehicle.groupName ? `${vehicle.groupName}:` : 'Not assigned to a group:'
                                } ${vehicle.carName} (${Make} ${Model} ${ModelYear}) - VIN: ${vehicle.vin}`}
                            />
                          </Box>
                      )
                    })}
                  </>
              )}
            </Box>
            <Box role="tabpanel" hidden={value !== 2} id={`vertical-tabpanel-2`} aria-labelledby={`vertical-tab-2`}>
              {value === 2 && (
                  <>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                          label="Contact Name"
                          name="contactName"
                          value={group?.contactName || ''}
                          onChange={handleInputChange}
                          InputProps={{ readOnly: !isEditing }}
                          variant="outlined"
                          required
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                          label="Contact's Email"
                          name="ownerEmail"
                          value={group?.ownerEmail || ''}
                          onChange={handleInputChange}
                          InputProps={{ readOnly: !isEditing }}
                          variant="outlined"
                          required
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                          label="Contact's Phone (Optional)"
                          name="ownerPhone"
                          value={group?.ownerPhone || ''}
                          onChange={handleInputChange}
                          InputProps={{ readOnly: !isEditing }}
                          variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel id="languagepreferenceoptional">Language Preference (Optional)</InputLabel>
                      <Select
                          labelId="language-label"
                          id="language-select"
                          name="languagePreference"
                          value={group?.languagePreference || 'en'}
                          onChange={handleInputChange}
                          label="languagepreferenceoptional"
                          inputProps={{ readOnly: !isEditing }}
                          variant="outlined"
                      >
                        {LanguagesPreferences.map((language) => (
                            <MenuItem key={language.code} value={language.code}>
                              {language.name}
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
  )
}

export default GroupEdit