import React, { useContext, useState, useEffect, createContext, useMemo } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { useLocation } from 'react-router-dom'
import {
  fetchInviteData,
} from './helpers'
import { getAuthInstance } from '../database/services/users'
import {getUserByUidAPI, updateUserAPI} from "../api/users";
import {getCustomerAPI, updateCustomerAPI } from "../api/customers";

const defaultUserContext = {
  user: null,
  company: null,
}

const UserContext = createContext(defaultUserContext)

export const useUser = () => useContext(UserContext)

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const setToStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

const getFromStorage = (key) => {
  const value = localStorage.getItem(key)
  try {
    return value ? JSON.parse(value) : null
  } catch (error) {
    return null
  }
}

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(getFromStorage('user'))
  const [userError, setUserError] = useState(false)
  const [invitation, setInvitation] = useState(null)
  const [company, setCompany] = useState(getFromStorage('company'))
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [authUserData, setAuthUserData] = useState(null)
  const [loadingUser, setLoadingUser] = useState(true)
  const [loadingUserContext, setLoadingUserContext] = useState(true)
  const [loadingUserInviteData, setLoadingUserInviteData] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const location = useLocation()
  const [backendUrl, setBackendUrl] = useState('')

  // BackendUrl
  useEffect(() => {
    let url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`
    if (url.includes(':3000')) {
      url = url.replace(':3000', ':5000')
      url = url.replace('localhost', '127.0.0.1')
    } else {
      // Dev environment: change URL to point to production backend
      url = process.env.REACT_APP_BACKEND_URL
    }
    setBackendUrl(url)
  }, [location])

  // Invitation state from path
  useEffect(() => {
    fetchInviteData(location, setInvitation, setLoadingUserInviteData)
  }, [])

  // Invitation state from path

  const loadInvite = async (invitation, email, displayName, uid) => {
    if (invitation?.type === 'companyOnboarding') {
      const customer = await updateCustomerAPI(invitation.id).then(response => response.json());
      return await updateUserAPI(invitation.id, customer.creator_id, displayName, uid, 'ADMIN').then(response => response.json());
    } else if (invitation?.type === 'companyInvite') {
      return await updateUserAPI(invitation.companyId, invitation.id, displayName, uid, 'USER').then(response => response.json());
    } else if (invitation?.type === 'signInLinkSent') {
      // Do nothing
    }
    return null
  }

  const loadUserToContext = async () => {
    setLoadingUser(true)
    setLoadingUserContext(true)
    setIsLoggedIn(true)

    const displayName = authUserData.displayName
    const email = authUserData.email
    const uid = authUserData.uid
    let userData = null

    if (invitation) {
      if (invitation.userEmail !== undefined && invitation.userEmail !== email) {
        setUserError(true)
        return
      }
      userData = await loadInvite(invitation, email, displayName, uid)
      if (!userData){
        setUserError(true)
        return
      }

      // No invitation data
    } else {
      console.log('No invitation')
      userData = (await getUserByUidAPI(uid).then(response => response.json()))[0]
    }

    if (!userData || userData?.customer_id == null) {
      // User has no invitation and no assigned company
      setUserError(true)
      return
    }

    userData.customer_new_id = userData.customer_id //TODO Remove once migration is done
    userData.customer_id = userData.external_customer_id //TODO Remove once migration is done
    userData.role = userData.role_type
    console.log('user: ', userData)
    setUser(userData)
    setToStorage('user', userData)
    setLoadingUser(false)
    setLoadingUserContext(false)
  }

  // Auhentication
  useEffect(() => {
    const auth = getAuthInstance()
    const unsubscribe = onAuthStateChanged(auth, async (authStateChangeData) => {
      setLoadingUserContext(true)
      if (authStateChangeData) {
        setAuthUserData(authStateChangeData)
      } else {
        setUserError(false)
        setUser(null)
        setCompany(null)
        setIsLoggedIn(false)
        localStorage.removeItem('user')
        localStorage.removeItem('company')
      }
      setLoadingUserContext(false)
    })
    return () => unsubscribe()
  }, [])

  // User state load
  useEffect(() => {
    if (authUserData && invitation?.type !== 'signInLinkSent') {
      loadUserToContext()
    }
  }, [authUserData, invitation])

  // Fetch company data if user has a companyId
  useEffect(() => {
    const fetchCompanyDataAsync = async function () {
      if (user?.customer_new_id) {
        console.log('Fetching company data', user.customer_new_id)
        const company = await getCustomerAPI(user.customer_new_id).then(response => response.json())
        if (company) {
          const companyData = { ...company, id: company.external_id, new_id: company.id }
          setCompany(companyData)
          setToStorage('company', companyData)
        }
      }
    }
    if (!loadingUser) {
      fetchCompanyDataAsync()
    }
  }, [user?.customer_new_id, loadingUser])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackbar(false)
  }

  const value = useMemo(
    () => ({
      backendUrl,
      user,
      setUser,
      company,
      setCompany,
      userError,
      invitation,
      setInvitation,
      loadingUserContext,
      loadingUserInviteData,
      isLoggedIn,
    }),
    [
      backendUrl,
      user,
      setUser,
      company,
      setCompany,
      userError,
      invitation,
      setInvitation,
      loadingUserContext,
      loadingUserInviteData,
      isLoggedIn,
    ]
  )

  return (
    <UserContext.Provider value={value}>
      {children}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ top: { xs: '20px', md: '20px' } }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Hello {user?.name || 'there'}!
        </Alert>
      </Snackbar>
    </UserContext.Provider>
  )
}
