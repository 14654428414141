import VisibilityIcon from '@mui/icons-material/Visibility'
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined'
import ArticleIcon from '@mui/icons-material/Article'
import { ThumbDown, ThumbUp } from '@mui/icons-material'

export const correctAnswerOptions = [
    { value: 'approved', label: 'Case Approved' },
    { value: 'rejected', label: 'Case Rejected' },
]

export const rejectionReasonOptions = [
    { value: 'Missing Document', label: 'Missing Document' },
    { value: 'Expired Document', label: 'Expired Document' },
    {
        value: 'Inconsistent VIN, name or other important information',
        label: 'Inconsistent VIN, name or other important information',
    },
    { value: 'Application filled wrongly', label: 'Application filled wrongly' },
    { value: 'Other', label: 'Other' },
]

export const actionIcons = {
    viewDocument: { tooltip: 'View Document', icon: <ArticleIcon /> },
    rationale: { tooltip: 'View details', icon: <VisibilityIcon /> }
  }

export const feedbackOptions = {
    thumbsup: { tooltip: 'Result is correct', icon: <ThumbUp /> },
    thumbsdown: { tooltip: 'Result is incorrect', icon: <ThumbDown /> },
}