import { DataGrid } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { useColorMode } from '../theme'
import React from 'react'

const CustomDataGrid = ({
  rows,
  columns,
  pageSize = 25,
  loading = false,
  autoHeight = true,
  disableRowSelectionOnClick = true,
  onColumnResize,
  rowsSelectable = false,
  selectedRows = [],
  onRowsSelected, // This is a custom prop that will be called when rows are selected,
  initialSortModel = [],
  ...props
}) => {
  const { theme } = useColorMode()

  const [sortModel, setSortModel] = React.useState(initialSortModel);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      sx={{
        width: '100%',
        overflowX: 'auto',
        overflowY: 'auto',
        paddingBottom: '0px',
        '&::-webkit-scrollbar': {
          width: '12px',
          height: '12px',
        },
        '&::-webkit-scrollbar-track': {
          background: theme.palette.background.secondary,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.background.primary,
          borderRadius: '10px',
          border: `3px solid ${theme.palette.background.secondary}`,
        },
        '& *': {
          scrollbarWidth: 'thin',
          scrollbarColor: `${theme.palette.background.primary} ${theme.palette.background.secondary}`,
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSizeOptions={[25, 50, 100]}
        loading={loading}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        onColumnResize={onColumnResize}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: pageSize,
            },
          },
        }}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        checkboxSelection={rowsSelectable}
        onRowSelectionModelChange={(newSelection) => {
          const selectedIDs = new Set(newSelection);
          const selectedRowsData = rows.filter((row) => selectedIDs.has(row.id));
          onRowsSelected(selectedRowsData);
        }}
        sx={{
          '& .MuiDataGrid-row': {
            minHeight: '50px',
          },
        }}
        {...props}
      />
    </Box>
  )
}

export default CustomDataGrid