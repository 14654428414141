import React, { useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { useUser } from '../contexts/UserContext'

const ConfirmationModal = ({
  open,
  onClose,
  handleConfirm,
  selectedRun,
  confirmationMessage,
  inProgress = false,
}) => {
  const confirmFunction = async () => {
    handleConfirm.then(async (result) => await result(selectedRun))
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Confirm Action</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {inProgress ? (
            <>
              <CircularProgress size={20} />
              <span> Operation in progress... </span>
            </>
          ) : (
            confirmationMessage
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={inProgress}>
          Cancel
        </Button>
        <Button onClick={confirmFunction} color="primary" disabled={inProgress}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal
