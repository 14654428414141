import React from 'react';
import { Snackbar, Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const FairwaySnackbar = ({ open, onClose, message, type }) => {
    const getColor = () => {
        if (type === 'success') {
            return '#118045';
        } else if (type === 'error') {
            return '#FF0000';
        }

    }

    const getBackgroundColor = () => {
        if (type === 'success') {
            return '#002410';
        } else if (type === 'error') {
            return '#FF0000';
        }
    }

    const getBorderColor = () => {
        if (type === 'success') {
            return '#00461F';
        } else if (type === 'error') {
            return '#FF0000';
        }
    }

    return (
        <Snackbar
            open={open}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={3000}
        >
            <Box display="flex" alignItems="center"
                sx={
                    {
                        backgroundColor: getBackgroundColor(),
                        border: '1px solid',
                        borderColor: getBorderColor(),
                        borderRadius: '8px',
                        padding: '12px',
                        gap: '6px',
                        color: 'white',
                        width: 'auto',
                        height: '42px',
                    }
                }
            >
                <CheckCircleIcon
                    sx={{
                        color: getColor(),
                        marginRight: '12px',
                    }}
                />
                <Typography fontWeight="ligth">
                    {message}
                </Typography>
            </Box>
        </Snackbar>
    );
};

export default FairwaySnackbar;
