import { getAuth, onAuthStateChanged } from 'firebase/auth'

const getIdToken = async () => {
    const auth = getAuth()
    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const token = await user.getIdToken()
                    resolve(token)
                } catch (error) {
                    reject(error)
                }
            } else {
                reject(new Error('No user is signed in'))
            }
        })
    })
}

export const fetchWithAuth = async (url, options = {}) => {
    const token = await getIdToken()
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    }

    const mergedHeaders = { ...headers, ...(options.headers || {}) }

    const response = await fetch(url, {
        ...options,
        headers: mergedHeaders,
        mode: 'cors',
    })

    if (!response.ok) {
        const contentType = response.headers.get('Content-Type')
        if (contentType && contentType.includes('application/problem+json')) {
            const errorData = await response.json()
            alert(errorData.detail || 'An error occurred')
            // throw new Error(errorData.detail || 'An error occurred')
        } else {
            // throw new Error(`HTTP error! status: ${response.status}`)
        }
    }

    return response
}


export const fetchNoAuth = async (url, options = {}) => {
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    }

    const mergedHeaders = { ...headers, ...(options.headers || {}) }

    const response = await fetch(url, {
        ...options,
        headers: mergedHeaders,
        mode: 'cors',
    })

    if (!response.ok) {
        const contentType = response.headers.get('Content-Type')
        if (contentType && contentType.includes('application/problem+json')) {
            const errorData = await response.json()
            alert(errorData.detail || 'An error occurred')
            // throw new Error(errorData.detail || 'An error occurred')
        } else {
            // throw new Error(`HTTP error! status: ${response.status}`)
        }
    }

    return response
}
