import { useState, useEffect } from 'react'
import { Grid, Box, Typography, Button, TextField } from '@mui/material'
import CustomDataGrid from '../../components/CustomDataGrid'
import {fetchAdminUsersAPI, inviteUserAPI} from "../../api/users";
import { useUser } from "../../contexts/UserContext";

const columns = [{ field: 'email', headerName: 'Email', width: 300, headerAlign: 'center', searchable: false }]

const FairwayAdmins = () => {
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [newEmail, setNewEmail] = useState('')
  const { user, company } = useUser()

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const usersList = await fetchAdminUsersAPI().then(response => response.json());
        setRows(usersList)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data: ', error)
        setLoading(false)
      }
    }
    loadUsers()
  }, [])

  const handleInvite = async () => {
    try {
      const newUser = await inviteUserAPI(company.id, newEmail.toLowerCase(), user.id, 'ADMIN', true, false).then(response => response.json());
      setRows((prevRows) => [...prevRows,
        {
          id: newUser.email,
          email: newUser.email
        }])
      setNewEmail('')
    } catch (error) {
      console.error('Error inviting user: ', error)
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <CustomDataGrid
            rows={rows}
            columns={columns}
            loading={loading}
            autoHeight
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            Super admins
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <TextField label="Email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} fullWidth />
            <Button onClick={handleInvite} variant="contained" sx={{ ml: 2 }}>
              Add
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FairwayAdmins
