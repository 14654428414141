import React from 'react';
import { Modal, Box, Typography, IconButton, Table, TableBody, TableRow, TableCell } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';

const DocumentsModal = ({ documentsModalOpen, handleDocumentsCloseModal, selectedRun }) => {
    return (
        <Modal
            open={documentsModalOpen}
            onClose={handleDocumentsCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    maxHeight: '90%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    overflowY: 'auto',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography variant="h6" id="modal-modal-title">
                        Documents - {selectedRun?.id}{' '}
                    </Typography>
                    <IconButton onClick={handleDocumentsCloseModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                {selectedRun && (
                    <Box>
                        <Table>
                            <TableBody>
                                {Object.keys(selectedRun.files || {}).map((key) => (
                                    <TableRow key={key}>
                                        <TableCell>{selectedRun.files[key].filename}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                component="a"
                                                href={selectedRun.files[key].url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default DocumentsModal;