import { useState, useEffect } from 'react'
import { useColorMode } from '../../theme'
import {
  Grid,
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import CustomDataGrid from '../../components/CustomDataGrid'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import { fetchDrivers, deleteDriver } from '../../database/services/drivers'

const DriversView = () => {
  const { theme } = useColorMode()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedDriver, setSelectedDriver] = useState(null)
  const navigate = useNavigate()
  const { user } = useUser()

  const handleAddDriver = () => {
    navigate(`/edit-driver/new`)
  }

  useEffect(() => {
    const fetchDriversData = async () => {
      try {
        const driversData = await fetchDrivers(user.customer_id)
        setRows(driversData)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    fetchDriversData()
  }, [user.customer_id])

  const handleEditClick = (row) => {
    navigate(`/edit-driver/${row.id}`)
  }

  const handleDeleteClick = (row) => {
    setSelectedDriver(row)
    setDeleteDialogOpen(true)
  }

  const handleDeleteConfirm = async () => {
    if (selectedDriver) {
      try {
        await deleteDriver(user.customer_id, selectedDriver.id)
        setRows(rows.filter((row) => row.id !== selectedDriver.id))
        setDeleteDialogOpen(false)
        setSelectedDriver(null)
      } catch (error) {
        console.error('Error deleting driver: ', error)
      }
    }
  }

  const columns = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 160 },
    { field: 'phone', headerName: 'Phone', width: 120 },
    {
      field: 'vehicles',
      headerName: 'Vehicle(s)',
      width: 300,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" mt="15px">
          {params.value.map((vehicle, index) => (
            <Typography key={index} variant="body2">
              {vehicle.name} ({vehicle.year} {vehicle.make} {vehicle.model})
            </Typography>
          ))}
        </Box>
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 90,
      renderCell: (params) => (
        <Tooltip title="Edit Driver">
          <IconButton onClick={() => handleEditClick(params.row)}>
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 90,
      renderCell: (params) => (
        <Tooltip title="Delete Driver">
          <IconButton onClick={() => handleDeleteClick(params.row)}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ]

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomDataGrid
            rows={rows}
            columns={columns}
            pageSize={100}
            loading={loading}
            // autoHeight and disableRowSelectionOnClick are default props
          />
        </Grid>
        {isMobile && (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: -1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDriver}
              sx={{
                height: '56px',
                minWidth: '100px',
                padding: '0 16px',
              }}
            >
              + DRIVER
            </Button>
          </Grid>
        )}
      </Grid>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Driver'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this driver?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default DriversView
