import {fetchNoAuth, fetchWithAuth} from "./utils";

export const inviteCustomerAPI = async (companyName, email, invitedBy) => {
    const url = `${process.env.REACT_APP_CORE_API_BASE_URL}/customers`
    return fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({name: companyName, email: email, invited_by_id: invitedBy, invite_accepted: false}),
    })
}

export const deleteCustomerAPI = async (customerId) => {
    const url = `${process.env.REACT_APP_CORE_API_BASE_URL}/customers/${customerId}`
    return fetchWithAuth(url, {
        method: 'DELETE',
    })
}

export const fetchCustomersAPI = async () => {
    const url = `${process.env.REACT_APP_CORE_API_BASE_URL}/customers`
    return fetchWithAuth(url, {
        method: 'GET'
    })
}

export const getCustomerAPI = async (customerID) => {
    const url = `${process.env.REACT_APP_CORE_API_BASE_URL}/customers/${customerID}`
    return fetchNoAuth(url, {
        method: 'GET'
    })
}

export const updateCustomerAPI = async (companyID) => {
    const url = `${process.env.REACT_APP_CORE_API_BASE_URL}/customers/${companyID}`
    return fetchNoAuth(url, {
        method: 'PUT',
        body: JSON.stringify({
            invite_accepted: true,
        }),
    })
}