import React from 'react';
import { Chip, Box } from '@mui/material';

const FairwayChip = ({ displayText, color}) => {
    const colorMap = {
        success: {
            backgroundColor: '#000904',
            borderColor: '#003618',
            dotColor: '#4AAA76',
        },
        error: {
            backgroundColor: '#170202',
            borderColor: '#430505',
            dotColor: '#C86969',
        },
        warning : {
            backgroundColor: '#0B0901',
            borderColor: '#392D04',
            dotColor: '#AD974B',
        },
        information : {
            backgroundColor: '#060D1A',
            borderColor: '#0F2F67',
            dotColor: '#346CCD',
        },
    };

    return (
        <Chip
            color="default"
            size="medium"
            variant="outlined"
            label={
                <Box display="flex" alignItems="center" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Box
                        component="span"
                        sx={{
                            display: 'inline-block',
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            backgroundColor: colorMap[color].dotColor,
                            marginRight: 1,
                            flexShrink: 0,
                        }}
                    ></Box>
                    {displayText}
                </Box>
            }
            style={{
                color: 'white',
                borderColor: colorMap[color].borderColor,
                backgroundColor: colorMap[color].backgroundColor,
                marginLeft: 10,
                maxWidth: '100%',
            }}
        />
    );
};

export default FairwayChip;