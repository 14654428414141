import {fetchNoAuth, fetchWithAuth} from "./utils";

export const inviteUserAPI = async (companyId, email, invitedBy, role, superAdmin = false, isOwner = false) => {
    const url = `${process.env.REACT_APP_CORE_API_BASE_URL}/customers/${companyId}/users`
    return fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({email: email, role_type: role, invited_by_id: invitedBy, super_admin:superAdmin, is_owner: isOwner}),
    })
}

export const fetchUsersAPI = async (companyId) => {
    const url = `${process.env.REACT_APP_CORE_API_BASE_URL}/customers/${companyId}/users?invite_accepted=true`
    return fetchWithAuth(url, {
        method: 'GET'
    })
}

export const deleteInvitedUserAPI = async (companyId, userID) => {
    const url = `${process.env.REACT_APP_CORE_API_BASE_URL}/customers/${companyId}/users/${userID}`
    return fetchWithAuth(url, {
        method: 'DELETE',
    })
}

export const fetchInvitedUsersAPI = async (companyId) => {
    const url = `${process.env.REACT_APP_CORE_API_BASE_URL}/customers/${companyId}/users?invite_accepted=false`
    return fetchWithAuth(url, {
        method: 'GET'
    })
}

export const fetchAdminUsersAPI = async () => {
    const url = `${process.env.REACT_APP_CORE_API_BASE_URL}/users?super_admin=true`
    return fetchWithAuth(url, {
        method: 'GET'
    })
}

export const getUserAPI = async (userID) => {
    const url = `${process.env.REACT_APP_CORE_API_BASE_URL}/users/${userID}`
    return fetchNoAuth(url, {
        method: 'GET'
    })
}

export const getUserByUidAPI = async (userUID) => {
    const url = `${process.env.REACT_APP_CORE_API_BASE_URL}/users?uid=${userUID}`
    return fetchNoAuth(url, {
        method: 'GET'
    })
}

export const updateUserAPI = async (companyID, userID, displayName, uid, role) => {
    const url = `${process.env.REACT_APP_CORE_API_BASE_URL}/customers/${companyID}/users/${userID}`
    return fetchNoAuth(url, {
        method: 'PUT',
        body: JSON.stringify({
            name: displayName,
            firebase_uid: uid,
            role: role,
            invite_accepted: true,
        }),
    })
}