import { orderBy, query } from 'firebase/firestore'
import { readDocument, createDocument, deleteDocument, updateDocument, setCollection, subscribeToCollection, updateDocuments, deleteDocuments } from '../databaseUtils'

export const addAuditorRun = async ({ customer_id, name, status, run_datetime, user_name, user_id, files }) => {
  const newRunFile = {
    name,
    customer_id,
    status,
    run_datetime,
    user_name,
    user_id,
    files,
  }

  const collectionPath = ['customers', customer_id, 'tools', 'AvaAuditor', 'runs']

  const result = await createDocument(collectionPath, newRunFile)
  return result ? { id: result.id } : null
}

export const archiveAuditorRun = async (companyId, runId) => {
  return await updateDocument(['customers', companyId, 'tools', 'AvaAuditor', 'runs'], runId, {
    status: 'Archived',
  })
}

export const batchArchiveAuditorRuns = async (companyId, runIds) => {
  const data = runIds.map(runId => ({
    id: runId,
    data: { status: 'Archived' }
  }))
  return await updateDocuments(['customers', companyId, 'tools', 'AvaAuditor', 'runs'], data)
}

export const unarchiveAuditorRun = async (companyId, runId) => {
  let archived_run = await readDocument(['customers', companyId, 'tools', 'AvaAuditor', 'runs', runId])

  if (archived_run.rationale)
    return await updateDocument(['customers', companyId, 'tools', 'AvaAuditor', 'runs'], runId, {
      status: 'Done',
    })

  return await updateDocument(['customers', companyId, 'tools', 'AvaAuditor', 'runs'], runId, {
    status: 'Error',
  })
}

export const batchUnarchiveAuditorRuns = async (companyId, runIds) => {
  const data = runIds.map(runId => ({
    id: runId,
    data: { status: 'Done' }
  }))
  return await updateDocuments(['customers', companyId, 'tools', 'AvaAuditor', 'runs'], data)
}

export const deleteAuditorRun = async (companyId, runId) => {
  return await deleteDocument(['customers', companyId, 'tools', 'AvaAuditor', 'runs', runId])
}

export const batchDeleteAuditorRuns = async (companyId, runIds) => {
  const data = runIds.map(runId => ({
    id: runId
  }))
  return await deleteDocuments(['customers', companyId, 'tools', 'AvaAuditor', 'runs'], data)
}

const queryRuns = (companyId, filter) =>
  query(
    setCollection(['customers', companyId, 'tools', 'AvaAuditor', 'runs']),
    filter,
    orderBy('run_datetime', 'desc'))

export const subscribeToAuditorRuns = (companyId, filter, onUpdate, onError) =>
  subscribeToCollection(queryRuns(companyId, filter), onUpdate, onError)
